import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import { useCookie } from "../hooks/useCookie"

export const CookieBanner = () => {
  const { loading, hasAcceptedCookies, setAcceptedCookies } = useCookie()

  const acceptByScrolling = () => {
    if (window.pageYOffset > 300) {
      setAcceptedCookies(true)
      window.removeEventListener("scroll", acceptByScrolling)
    }
  }

  const setupScrollListener = useCallback(
    () => window.addEventListener("scroll", acceptByScrolling),
    []
  )

  const teardownEventListener = useCallback(
    () => window.removeEventListener("scroll", acceptByScrolling),
    []
  )

  useEffect(() => {
    if (!loading && hasAcceptedCookies === null) {
      setupScrollListener()
    }
    return () => {
      teardownEventListener()
    }
  }, [loading, hasAcceptedCookies])

  return !loading && hasAcceptedCookies === null ? (
    <Container>
      <TextContainer>
        The Big Button uses cookies to give you the best browsing experience.
        {"\n"}By browsing you accept our{" "}
        <Link
          href="https://www.iubenda.com/privacy-policy/59649665"
          className="iubenda-nostyle no-brand iubenda-embed"
          title="Cookie Policy"
        >
          cookie policy
        </Link>
      </TextContainer>
      <ActionContainer>
        <Accept
          onClick={() => {
            setAcceptedCookies(true)
            teardownEventListener()
          }}
        >
          Ok
        </Accept>
        <Deny
          onClick={() => {
            setAcceptedCookies(false)
            teardownEventListener()
          }}
        >
          Deny
        </Deny>
      </ActionContainer>
    </Container>
  ) : null
}

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  right: 10px;
  bottom: 10px;
  margin: auto;
  width: 200px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};

  font-size: 13px;
  flex-direction: column;
  @media (max-width: 500px) {
    left: 10px;
    width: auto;
  }
  a {
    color: ${({ theme }) => theme.colors.white};
    transition: ${({ theme }) => theme.styles.transition};
    &:hover {
      color: ${({ theme }) => theme.colors.mainDark};
    }
  }
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 10px;
  button {
    border: none;
    margin-left: 5px;
  }
`

const TextContainer = styled.span``

const Link = styled.a``

const Accept = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 4px 10px;
  border-radius: 2px;
`

const Deny = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
`
