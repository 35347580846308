import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { CookieBanner } from "../components/CookieBanner"
import { CookieProvider } from "../hooks/useCookie"
import Helmet from "react-helmet"

const theme = {
  colors: {
    yellow: "#FFAD00",
    black: "#000",
    white: "#FFFFFF",
  },
  fonts: {
    main: "Inter, sans-serif",
  },
  styles: {
    boxShadow: "0 3px 15px rgba(0, 0, 0, 0.1)",
    boxShadowHover: "0 3px 15px rgba(0, 0, 0, 0.8)",
    transition: "0.3s all",
  },
}

const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  body {
    background-color: ${theme.colors.yellow};
    font-family: ${theme.fonts.main};
    font-size: 14px;
   color: ${theme.colors.black};
   margin: 0;
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script src="https://cdn.iubenda.com/iubenda.js"></script>
        <script src="/js/iubenda.js" async defer />
      </Helmet>
      <CookieProvider>
        <GlobalStyle />
        <CookieBanner></CookieBanner>
        <Wrapper>
          <main>{children}</main>
          <Footer>
            © {new Date().getFullYear()}, Built with ☕ by
            {` `}
            <a href="https://inkofpixel.com">inkOfPixel</a>
            {` • `}
            <a
              href="https://www.iubenda.com/privacy-policy/59649665"
              className="iubenda-nostyle no-brand iubenda-embed"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            {", "}
            <a
              href="https://www.iubenda.com/terms-and-conditions/59649665"
              className="iubenda-nostyle no-brand iubenda-embed"
              title="Terms and conditions"
            >
              Terms and conditions
            </a>
          </Footer>
        </Wrapper>
      </CookieProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  width: 960px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 20px;
  }
`

const Footer = styled.footer`
  margin-top: 160px;
  @media (max-width: 500px) {
    margin-top: 100px;
  }
  a {
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      font-weight: 500;
    }
  }
`
