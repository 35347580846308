import localForage from "localforage"

const objectStore = localForage.createInstance({
  name: "valuta",
})

export const getPersistedCookieAcceptance = () =>
  objectStore.getItem("hasAcceptedCookies")

export const setPersistedCookieAcceptance = cookieAcceptance =>
  objectStore.setItem("hasAcceptedCookies", cookieAcceptance)

export const deletePersistedCookieAcceptance = () => objectStore.clear()
