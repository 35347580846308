import React, { useContext, useMemo, useState, useEffect } from "react"
import {
  setPersistedCookieAcceptance,
  getPersistedCookieAcceptance,
} from "./persistence"

const CookieContext = React.createContext(null)

export function CookieProvider({ children }) {
  const [hasAcceptedCookies, setAcceptedCookies] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getAcceptance = async () => {
      const accepted = await getPersistedCookieAcceptance()
      setAcceptedCookies(accepted)
      setLoading(false)
    }
    getAcceptance()
  }, [])

  useEffect(() => {
    setPersistedCookieAcceptance(hasAcceptedCookies)
  }, [hasAcceptedCookies])

  const value = useMemo(
    () => ({
      loading,
      hasAcceptedCookies,
      setAcceptedCookies,
    }),
    [hasAcceptedCookies, loading]
  )
  return (
    <CookieContext.Provider value={value}>{children}</CookieContext.Provider>
  )
}

export function useCookie() {
  const value = useContext(CookieContext)
  if (!value) {
    throw new Error(`useCookie must be used inside a CookieProvider`)
  }

  return useMemo(
    () => ({
      ...value,
    }),
    [value]
  )
}
